<template>
    <v-snackbar v-model="messagePresent" top text rounded>
        {{ message }}
    </v-snackbar>
</template>

<script>
import { mapState } from "vuex";

const TIMEOUT = 2000;

export default {
    name: "ToastProvider",
    data() {
        return {
            messagePresent: false,
            timeout: setTimeout(() => {}),
        };
    },
    computed: {
        ...mapState(["message", "messageId"]),
    },
    watch: {
        messageId: function () {
            this.showMessage();
        },
    },
    methods: {
        showMessage() {
            this.messagePresent = true;
            clearTimeout(this.timeout);
            this.timeout = setTimeout(
                () => (this.messagePresent = false),
                TIMEOUT
            );
        },
    },
};
</script>
