<template>
    <v-container fluid>
        <div class="center">
            <template v-if="loading">
                <v-progress-circular class="my-3" :size="50" indeterminate />
                <h3>Loading...</h3>
            </template>
            <template v-else>
                <template v-if="$store.getters.isLoggedIn">
                    <h1 class="mb-4 center">
                        <v-icon x-large class="mr-2">
                            mdi-{{ eventDetails.icon }}
                        </v-icon>
                        <span>{{ eventDetails.label }}</span>
                    </h1>
                </template>
                <h1>
                    {{ formattedDate || "Target reached!" }}
                </h1>
                <flip-countdown
                    :deadline="safeDate"
                    @reachedDeadline="getEarliestEvent()"
                />
            </template>
        </div>
    </v-container>
</template>

<script>
import FlipCountdown from "../components/FlipCountdown";
import { eventsApi } from "@/mixins/api_interface";

export default {
    name: "CountdownView",
    components: {
        FlipCountdown,
    },
    mixins: [eventsApi],
    data() {
        return {
            eventDetails: null,
            loading: true,
        };
    },
    computed: {
        formattedDate() {
            return new Intl.DateTimeFormat("default", {
                dateStyle: "full",
                timeStyle: "short",
            }).format(this.safeDate * 1000);
        },
        safeDate() {
            return this.eventDetails?.date || new Date().getTime() / 1000;
        },
    },
    created() {
        this.getEarliestEvent();
    },
};
</script>
