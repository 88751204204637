import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/plugins/store";
import About from "@/views/About.vue";
import CountdownView from "@/views/CountdownView.vue";
import EventsView from "@/views/EventsView";
import LoginView from "@/views/LoginView.vue";
import LogoutView from "@/views/LogoutView";
import QuestionBankView from "@/views/QuestionBankView.vue";

Vue.use(VueRouter);

export const routeGroups = [
    [
        {
            path: "*",
            redirect: {
                name: "Countdown",
            },
        },
        {
            path: "/about",
            name: "About",
            component: About,
            meta: {
                icon: "help-circle-outline",
            },
        },
        {
            path: "/countdown",
            name: "Countdown",
            component: CountdownView,
            meta: {
                icon: "home",
            },
        },
    ],
    [
        {
            path: "/login",
            name: "Login",
            component: LoginView,
        },
        {
            path: "/logout",
            name: "Logout",
            component: LogoutView,
        },
        {
            path: "/create_account",
            name: "Create Account",
            meta: {
                icon: "account",
                externalRedirect: "https://bit.ly/2NXcQyj",
            },
        },
    ],
    [
        {
            path: "/question_bank",
            name: "Question Bank",
            component: QuestionBankView,
            meta: {
                icon: "piggy-bank",
                requiresAuth: true,
            },
        },
        {
            path: "/events",
            name: "Events",
            component: EventsView,
            meta: {
                icon: "calendar",
                requiresAuth: true,
            },
        },
    ],
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: routeGroups.reduce(
        (allRoutes, routeGroup) => [...allRoutes, ...routeGroup],
        []
    ),
});

router.beforeEach((to, from, next) => {
    if (to.name === from.name && to.fullPath === from.fullPath) {
        return false;
    }

    if (to.matched.some((record) => record.meta.externalRedirect)) {
        window.location.href = to.meta.externalRedirect;
        return false;
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        const redirectToLogin = {
            name: "Login",
            query: {
                redirect: to.fullPath,
            },
        };

        if (store.getters.isLoggedIn) {
            if (store.getters.tokenExpired) {
                store.dispatch("showMessage", "Token expired").then(() => {
                    store.dispatch("logout").then(() => {
                        return next(redirectToLogin);
                    });
                });
                return next();
            }
            return next();
        }
        store.dispatch("showMessage", "Login required").then();
        return next(redirectToLogin);
    }
    return next();
});

export default router;
