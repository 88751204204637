import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import jwt_decode from "jwt-decode";
import router from "@/plugins/router";

Vue.use(Vuex);

const getFromToken = (token, key) => {
    let decodedToken;
    try {
        decodedToken = jwt_decode(token);
    } catch {
        return undefined;
    }
    return decodedToken[key];
};

const store = new Vuex.Store({
    state: {
        token: localStorage.getItem("token") || "",
        baseUrl:
            process.env.NODE_ENV === "development"
                ? "http://localhost:5000"
                : window.location.origin.concat("/api"),
        message: "",
        messageId: -1,
    },
    getters: {
        isLoggedIn: (state) => !!state.token,
        tokenExpired: (state) =>
            (getFromToken(state.token, "exp") || 0) < Date.now() / 1000,
        displayName: (state) => getFromToken(state.token, "display_name"),
    },
    mutations: {
        login(state, token) {
            state.token = token;
        },
        logout(state) {
            state.token = "";
        },
        show_message(state, message) {
            state.message = message;
            state.messageId += 1;
        },
    },
    actions: {
        login({ commit }, user) {
            return new Promise((resolve, reject) => {
                axios
                    .post(this.state.baseUrl.concat("/authentication/login"), {
                        username: user.username,
                        password: user.password,
                    })
                    .then((resp) => {
                        const token = resp.data.token;
                        localStorage.setItem("token", token);
                        commit("login", token);
                        commit(
                            "show_message",
                            `Logged in as: ${
                                store.getters.displayName || "Unknown"
                            }`
                        );
                        resolve(resp);
                    })
                    .catch((error) => {
                        localStorage.removeItem("token");
                        const errorMessage =
                            error.response?.data.message || error.message;
                        commit("show_message", errorMessage);
                        reject(errorMessage);
                    });
            });
        },
        logout({ commit }) {
            return new Promise((resolve) => {
                localStorage.removeItem("token");
                commit("logout");
                resolve();
            });
        },
        showMessage({ commit }, message) {
            return new Promise((resolve) => {
                commit("show_message", message);
                resolve();
            });
        },
    },
    modules: {},
});

axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

axios.interceptors.response.use(
    undefined,
    (error) =>
        new Promise(() => {
            if (error.response.status === 401) {
                router
                    .push({
                        name: "Logout",
                        query: {
                            redirect: "Login",
                        },
                    })
                    .then(() => store.dispatch("showMessage", "Token expired"));
            }
            throw error;
        })
);

export default store;
