<template>
    <v-app>
        <ToastProvider />
        <NavigationBar
            :current-view="currentView || ''"
            @changeView="changeView($event)"
        />
        <v-main>
            <router-view @changeView="changeView($event)" />
        </v-main>
    </v-app>
</template>

<script>
import NavigationBar from "./components/NavigationBar";
import ToastProvider from "@/components/MessageProvider";

export default {
    name: "App",
    components: { ToastProvider, NavigationBar },
    data() {
        return {
            currentView: this.$router.currentRoute.name?.toString(),
        };
    },
    methods: {
        changeView(newRoute) {
            this.$router.push(newRoute).catch(() => {});
            this.currentView = newRoute.name;
        },
    },
};
</script>

<style>
html {
    overflow-y: auto;
}

.center {
    text-align: center;
}
</style>
