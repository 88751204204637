import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import DatetimePicker from "vuetify-datetime-picker";

Vue.use(Vuetify);
Vue.use(DatetimePicker);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            light: {
                primary: "#222222",
                secondary: "#00cccc",
            },
            dark: {
                primary: "#cccccc",
                secondary: "#005555",
            },
        },
    },
});
