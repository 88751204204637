<template>
    <v-tooltip bottom>
        <template #activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                class="px-2 mx-2"
                @click="isLoggedIn ? logout() : login()"
                v-on="on"
            >
                {{ isLoggedIn ? "Logout" : "Login" }}
                <v-icon class="ml-2">
                    {{ isLoggedIn ? "mdi-logout" : "mdi-login" }}
                </v-icon>
            </v-btn>
        </template>
        <span>
            {{ isLoggedIn ? "Log out" : "Log in" }}
        </span>
    </v-tooltip>
</template>

<script>
export default {
    name: "LogInOutButton",
    computed: {
        isLoggedIn() {
            return this.$store.getters.isLoggedIn;
        },
    },
    methods: {
        logout() {
            this.$emit("changeView", { name: "Logout" });
        },
        login() {
            this.$emit("changeView", { name: "Login" });
        },
    },
};
</script>
