<template>
    <v-container>
        <v-card class="pa-5 ma-4" rounded>
            <h1>Question Bank</h1>
            <h3>Total Questions: {{ total }}</h3>
            <form @submit.prevent="addQuestion">
                <v-row justify="center">
                    <v-col cols="10">
                        <v-textarea
                            v-model="question"
                            name="question"
                            label="Question"
                            required
                            autofocus
                            auto-grow
                            rows="1"
                        />
                        <v-row justify="center" class="my-1">
                            <v-btn
                                :disabled="loading"
                                :loading="loading"
                                type="submit"
                            >
                                Add question
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-row>
            </form>
        </v-card>
    </v-container>
</template>

<script>
import { questionBankApi } from "@/mixins/api_interface";

export default {
    name: "QuestionBankView",
    mixins: [questionBankApi],
    data() {
        return {
            total: 0,
            question: "",
            loading: false,
        };
    },
    created() {
        this.getTotal();
    },
};
</script>
