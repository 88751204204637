<template>
    <v-container>
        <v-app-bar class="px-1" rounded>
            <v-row>
                <v-col class="col-6">
                    <v-row>
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-app-bar-nav-icon
                                    v-bind="attrs"
                                    @click="drawer = true"
                                    v-on="on"
                                />
                            </template>
                            <span>Open navigation bar</span>
                        </v-tooltip>
                        <v-toolbar-title class="ml-2">
                            <h2
                                class="text-decoration-none"
                                style="cursor: pointer"
                                @click="navigateTo('Countdown')"
                            >
                                Willpwa
                            </h2>
                        </v-toolbar-title>
                    </v-row>
                </v-col>
                <v-col class="col-6">
                    <v-row justify="end" align="center">
                        <log-in-out-button
                            @changeView="$emit('changeView', $event)"
                        />
                        <dark-mode-toggle />
                    </v-row>
                </v-col>
            </v-row>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" absolute temporary>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        Willpwa
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider />

            <v-list-item-group
                active-class="darken-1"
                :value="currentActiveView"
                @change="currentActiveView = $event"
            >
                <v-list nav>
                    <template
                        v-for="(group, groupIndex) in Object.values(views)"
                    >
                        <div :key="groupIndex">
                            <template v-for="(view, viewIndex) of group">
                                <v-list-item
                                    :key="viewIndex"
                                    ripple
                                    :value="view.name"
                                    @click="navigateTo(view.name)"
                                >
                                    <v-list-item-icon>
                                        <v-icon>
                                            {{ view.icon }}
                                        </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        {{ view.name }}
                                    </v-list-item-title>
                                </v-list-item>
                            </template>
                            <v-divider
                                v-if="
                                    groupIndex !== Object.keys(views).length - 1
                                "
                                class="mb-2"
                            />
                        </div>
                    </template>
                </v-list>
            </v-list-item-group>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
import LogInOutButton from "@/components/LogInOutButton";
import DarkModeToggle from "@/components/DarkModeToggle";
import { routeGroups } from "@/plugins/router";

export default {
    name: "NavigationBar",
    components: { LogInOutButton, DarkModeToggle },
    props: {
        currentView: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            currentActiveView: "",
            drawer: false,
            views: routeGroups
                .filter((routeGroup) =>
                    routeGroup.some((route) => !!route.meta)
                )
                .map((routeGroup) =>
                    routeGroup
                        .filter((route) => !!route.meta)
                        .map((route) => ({
                            name: route.name,
                            icon: `mdi-${route.meta.icon}`,
                        }))
                ),
        };
    },
    watch: {
        currentView: function (newValue) {
            this.currentActiveView = newValue;
        },
    },
    created() {
        this.currentActiveView = this.currentView;
    },
    methods: {
        navigateTo(name) {
            if (this.$router.currentRoute.name !== name) {
                this.$emit("changeView", { name });
            }
        },
    },
};
</script>
