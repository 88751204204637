<template>
    <v-container>
        <v-card class="pa-5 ma-4" rounded>
            <h1>About</h1>
            <br />
            <h3>What?</h3>
            <p>
                This is a website written using Vue.js with Vuetify. You are
                likely using your eyes to view the website through the use of
                some sort of light-based screen, although if you are accessing
                this by some other means: Good for you!
            </p>
            <h3>Why?</h3>
            <p>
                The purpose of this website is to host a countdown clock to be
                viewable by multiple people from multiple locations without the
                use of a third party website designed to do just that. The only
                difference is that I can control how this one looks and it's got
                a shorter URL. It also proved to be an entertaining way to learn
                how to use the underlying technologies.
            </p>
            <p>
                The significance of the countdown timer is private to those with
                whom the domain's URL was personally shared. However, in the
                event that you do not know the significance of the countdown
                clock, you probably won't really care anyway. If you know the
                website's author, then feel free to ask, otherwise tough luck.
            </p>
        </v-card>
    </v-container>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "About",
};
</script>
