<template>
    <v-tooltip bottom>
        <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" @click="toggleDarkMode" v-on="on">
                <v-icon>mdi-brightness-6</v-icon>
            </v-btn>
        </template>
        <span>Toggle dark mode</span>
    </v-tooltip>
</template>

<script>
export default {
    name: "DarkModeToggle",
    created() {
        this.setLocalStorage();
    },
    methods: {
        toggleDarkMode() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            this.setLocalStorage();
        },
        setLocalStorage() {
            localStorage.setItem(
                "dark_theme",
                this.$vuetify.theme.dark.toString()
            );
        },
    },
};
</script>
