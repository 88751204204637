<template>
    <v-container>
        <v-card class="pa-4 pa-sm-5 ma-sm-4" rounded>
            <h1>Events</h1>
            <div v-if="loading && allEvents.length === 0" class="center">
                <v-progress-circular class="my-3" :size="50" indeterminate />
                <h3>Loading...</h3>
            </div>
            <template v-else>
                <div class="mt-3">
                    <v-row
                        v-for="(eventDetails, index) in allEvents"
                        :key="eventDetails.id || index"
                        :class="index === allEvents.length - 1 ? 'mb-3' : ''"
                    >
                        <v-col class="mx-3 mx-sm-8">
                            <v-row justify="center">
                                <EventItem
                                    :details="eventDetails"
                                    @detailsChanged="pushEvent($event)"
                                    @delete="deleteEvent($event)"
                                />
                            </v-row>
                            <v-row
                                v-if="index !== allEvents.length - 1"
                                justify="center"
                            >
                                <v-icon large>mdi-arrow-down-bold</v-icon>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="my-1">
                        <v-col>
                            <v-row justify="center">
                                <v-btn large @click="addEvent">
                                    <v-icon large>mdi-plus</v-icon>
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </template>
        </v-card>
    </v-container>
</template>

<script>
import EventItem from "@/components/EventItem";
import { eventsApi } from "@/mixins/api_interface";

export default {
    name: "EventsView",
    components: {
        EventItem,
    },
    mixins: [eventsApi],
    data() {
        return {
            allEvents: [],
            loading: false,
        };
    },
    created() {
        this.getAllEvents();
    },
    methods: {
        addEvent() {
            this.allEvents.push({
                label: "New Event",
                icon: "calendar",
                date: new Date(),
            });
        },
        deleteEvent(eventId) {
            this.removeEvent(eventId);
            this.allEvents = this.allEvents.filter(
                (event) => event.id !== eventId
            );
        },
    },
};
</script>
