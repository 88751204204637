<template>
    <v-container>
        <span>
            You are being logged out. If you do not automatically redirect,
            click
            <a @click="goToNext">here</a>
        </span>
    </v-container>
</template>

<script>
export default {
    name: "LogoutView",
    created() {
        this.logout();
    },
    methods: {
        logout() {
            this.$store.dispatch("logout").then(() => {
                this.goToNext();
            });
        },
        goToNext() {
            const next = { name: "Countdown" };
            if (this.$router.currentRoute.name !== next.name) {
                this.$emit("changeView", this.$route.query.redirect || next);
            }
        },
    },
};
</script>
