<template>
    <v-card
        elevation="5"
        color="secondary"
        rounded
        class="my-3"
        style="width: 100%"
    >
        <v-form v-model="valid" lazy-validation>
            <v-row align="center" justify="center">
                <v-col class="col-2 col-sm-1">
                    <v-icon large class="pl-4">
                        mdi-{{ editDetails.icon }}
                    </v-icon>
                </v-col>
                <v-col class="col-8 col-sm-9">
                    <v-card-title>
                        <v-text-field
                            v-if="editing"
                            v-model="editDetails.label"
                            label="Event Title"
                            :rules="[rules.required]"
                            autofocus
                            @keydown="submitIfEnter"
                        />
                        <span v-else>{{ editDetails.label }}</span>
                    </v-card-title>
                    <v-card-subtitle>
                        <template v-if="editing">
                            <v-row>
                                <v-col>
                                    <v-datetime-picker
                                        v-model="editDetails.date"
                                        label="Event Time"
                                        :text-field-props="{
                                            rules: [rules.required],
                                        }"
                                    >
                                        <template slot="dateIcon">
                                            <v-icon>mdi-calendar</v-icon>
                                        </template>
                                        <template slot="timeIcon">
                                            <v-icon>mdi-clock</v-icon>
                                        </template>
                                    </v-datetime-picker>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        v-model="editDetails.icon"
                                        label="Event Icon"
                                        :rules="[rules.required]"
                                        @keydown="submitIfEnter"
                                    />
                                </v-col>
                            </v-row>
                        </template>
                        <span v-else>{{ parsedDate }}</span>
                    </v-card-subtitle>
                </v-col>
                <v-col class="col-2">
                    <v-row justify="end">
                        <template v-if="editing">
                            <v-btn
                                icon
                                large
                                class="mr-5 mr-lg-0"
                                @click="save"
                            >
                                <v-icon>mdi-check-bold</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                large
                                class="mr-5"
                                @click="revertChanges"
                            >
                                <v-icon>mdi-cancel</v-icon>
                            </v-btn>
                        </template>
                        <template v-else>
                            <v-btn
                                icon
                                large
                                class="mr-5 mr-lg-0"
                                @click="revertChanges"
                            >
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                large
                                class="mr-5"
                                @click="$emit('delete', details.id)"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
    </v-card>
</template>

<script>
export default {
    name: "EventItem",
    props: {
        details: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            editing: false,
            editDetails: {},
            valid: false,
            rules: {
                required: (value) => !!value || "This field is required",
            },
        };
    },
    computed: {
        parsedDate() {
            return new Intl.DateTimeFormat("default", {
                dateStyle: "full",
                timeStyle: "short",
            }).format(this.editDetails.date || this.details.date);
        },
    },
    created() {
        this.revertChanges(false);
        this.editing = !this.details.id;
    },
    methods: {
        toggleEdit() {
            this.editing = !this.editing;
        },
        revertChanges(toggleEdit = true) {
            this.editDetails = { ...this.details };
            if (!this.details.id && toggleEdit) {
                this.$emit("delete", null);
            }
            toggleEdit && this.toggleEdit();
        },
        save() {
            this.$emit("detailsChanged", {
                ...this.editDetails,
                id: this.details.id || null,
                date: this.editDetails.date.getTime() / 1000,
            });
            this.toggleEdit();
        },
        submitIfEnter(event) {
            if (event.key === "Enter") {
                this.save();
            }
        },
    },
};
</script>
