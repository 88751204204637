<template>
    <v-container>
        <v-card class="pa-5 ma-4" rounded>
            <h1>Log in</h1>
            <v-form :disabled="loading" @submit.prevent="login">
                <v-row justify="center">
                    <v-col xs="10" sm="8" lg="5">
                        <v-text-field
                            v-model="username"
                            name="username"
                            label="Username"
                            required
                            autofocus
                        />
                        <v-text-field
                            v-model="password"
                            name="password"
                            label="Password"
                            type="password"
                            required
                        />
                        <v-row justify="center" class="my-1">
                            <v-btn
                                :disabled="loading"
                                :loading="loading"
                                type="submit"
                            >
                                Log in
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: "LoginView",
    data() {
        return {
            username: "",
            password: "",
            loading: false,
        };
    },
    methods: {
        login() {
            const username = this.username;
            const password = this.password;
            this.loading = true;
            this.$store
                .dispatch("login", { username, password })
                .then(() => {
                    this.$emit(
                        "changeView",
                        this.$route.query.redirect || { name: "Countdown" }
                    );
                })
                .catch((error) => {
                    console.warn(error);
                    this.password = "";
                })
                .finally(() => (this.loading = false));
        },
    },
};
</script>
