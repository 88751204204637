import axios from "axios";

export const eventsApi = {
    methods: {
        getEarliestEvent() {
            this.loading = true;
            axios
                .get(
                    this.$store.state.baseUrl
                        .concat("/events/earliest")
                        .concat(
                            this.$store.getters.isLoggedIn ? "" : "_no_auth"
                        )
                )
                .then(
                    (response) =>
                        (this.eventDetails = response.data.event || {})
                )
                .catch((error) => console.debug(error))
                .finally(() => (this.loading = false));
        },
        getAllEvents() {
            this.loading = true;
            axios
                .get(this.$store.state.baseUrl.concat("/events/all"))
                .then(
                    (response) =>
                        (this.allEvents = response.data.events
                            .sort((event1, event2) => event1.date - event2.date)
                            .map((event) => ({
                                ...event,
                                date: new Date(event.date * 1000),
                            })))
                )
                .catch((error) => console.debug(error))
                .finally(() => (this.loading = false));
        },
        pushEvent(details) {
            axios
                .post(this.$store.state.baseUrl.concat("/events/add"), details)
                .then((response) =>
                    this.$store
                        .dispatch("showMessage", response.data.message)
                        .then(this.getAllEvents)
                )
                .catch((error) => console.debug(error));
        },
        removeEvent(eventId) {
            if (!eventId) return this.getAllEvents();
            axios
                .post(this.$store.state.baseUrl.concat("/events/remove"), {
                    id: eventId,
                })
                .then((response) =>
                    this.$store.dispatch("showMessage", response.data.message)
                )
                .catch((error) => console.debug(error));
        },
    },
};

export const questionBankApi = {
    methods: {
        getTotal() {
            axios
                .get(this.$store.state.baseUrl.concat("/question_bank/total"))
                .then((response) => (this.total = response.data.total));
        },
        addQuestion() {
            this.loading = true;
            axios
                .post(this.$store.state.baseUrl.concat("/question_bank/add"), {
                    question: this.question,
                })
                .then(() => {
                    this.$store
                        .dispatch("showMessage", "Question added successfully")
                        .then(() => {
                            this.question = "";
                            this.getTotal();
                        });
                })
                .finally(() => (this.loading = false));
        },
    },
};
